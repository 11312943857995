<template>
  <div>
    <b-card title="Add new client">
      <b-input-group class="mb-2">
        <b-input-group-prepend is-text>
          <feather-icon icon="UserIcon" />
        </b-input-group-prepend>
        <b-form-input v-model="clientName" placeholder="Enter Client Name" />
      </b-input-group>
      <vue-dropzone
        :useCustomSlot="true"
        ref="myVueDropzone"
        id="dropzone"
        :options="dropzoneOptions"
        @vdropzone-removed-file="removeFile"
      >
        <div class="dropzone-custom-content">
          <h3>Drag and drop to upload client logo!</h3>
          <div>...or click to select a file from your computer</div>
        </div>
      </vue-dropzone>

      <div class="text-center mt-2">
        <b-button variant="primary" @click="createClient">Save</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import {
  BCard,
  BButton,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
} from "bootstrap-vue";
import { getUserToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";

export default {
  components: {
    vueDropzone: vue2Dropzone,
    BCard,
    BButton,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    ToastificationContent,
  },
  data() {
    return {
      dropzoneOptions: {
        url: `${process.env.VUE_APP_SERVER_URL}/api/upload/single`,
        paramName: "media",
        method: "POST",
        thumbnailWidth: 150,
        addRemoveLinks: true,
        dictRemoveFile: "Remove",
        dictRemoveFileConfirmation:
          "This will delete the file from the server. Do you wish to continue?",
        addRemoveLinks: true,
        duplicateCheck: true,
        acceptedFiles: ".jpeg,.jpg,.png",
        headers: {
          Authorization: `Bearer ${getUserToken()}`,
        },
      },
      clientName: "",
    };
  },
  methods: {
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    removeFile(file) {
      return;
    },
    getLogo() {
      const files = this.$refs.myVueDropzone.getAcceptedFiles();
      return files;
    },
    createClient() {
      if (!this.clientName) {
        return this.showToast(
          "Error",
          "Client name cannot be empty!",
          "danger"
        );
      }
      const files = this.getLogo();
      const name = this.clientName;
      let logo = null;
      if (
        files.length &&
        files[0] &&
        files[0].xhr &&
        files[0].xhr.responseText
      ) {
        let file = files[0];
        let responseText = file.xhr.responseText;
        responseText = JSON.parse(responseText);
        if (responseText.cdn) {
          logo = responseText.cdn;
        }
      }

      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/add`,
          {
            name,
            logo,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.$router.push(`/client/${response.data.client.slug}`);
        })
        .catch((error) => {
          if (error.response.data && error.response.data.error) {
            this.showToast("Error", error.response.data.error, "danger");
            return;
          }

          if (error.response.data && error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              const error = error.response.data.errors[i];
              this.showToast("Error", `${error.msg - error.param}`, "danger");
            }
            return;
          }

          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
  },
};
</script>

<style scoped>
.vue-dropzone {
  border: 2px dashed #e5e5e5;
  font-family: "Montserrat", Helvetica, Arial, serif;
}
</style>